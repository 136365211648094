export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  }, {
    path: '/password-reset',
    name: 'password.reset',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/otp-verify',
    name: 'otp-verify',
    component: () => import('@/views/pages/authentication/ForgotPasswordOTPVerify.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/profile-password-change',
    name: 'profile-password-change',
    component: () => import('@/views/pages/ChangePassword.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/pages/authentication/Logout.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
    },
  },
]
